import React, { useEffect, useState } from 'react'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import SubBanner from '../components/SubBanner'
import ContactForm from '../components/ContactForm'
import { graphql } from 'gatsby'


export default function Contact({ data }) {
  const { title, bannerContent: { bannerContent }, bannerImage: { file: { url } }, businessLocation: { lon, lat }, phoneNumber, email, address: { address } } = data.contentfulContactContent
  const mapCoords = {
    lon: parseFloat(lon),
    lat: parseFloat(lat)
  }

  const [MapComp, setMapComp] = useState(null)

  // Imports MapView component dynamically and imports when ready - Fixes window undefined gatsby build issue
  const loadMap = async () => {
    const importedMap = await import('../components/Maps/MapView')
    setMapComp(importedMap.default)
  }

  useEffect(() => {
    loadMap()
  }, [])



  return (
    <Layout>
      <SEO title={title} description={bannerContent} />
      <SubBanner title={title} subtitle={bannerContent} image={url} />
      <div className="subpage-container lg:px-40">
        <div className="grid lg:grid-cols-2 py-10">
          <div>
            <h4 className="text-blue-800 text-xl font-semibold">Your Contact Details</h4>
            <ContactForm />
          </div>
          <div className="sm:px-10 z-10">
            {MapComp && <MapComp mapCoords={mapCoords} address={address} />}
            <div className="text-blue-800 flex flex-col py-6">
              <div className="text-xl font-bold py-2">Contact us today</div>
              <span>Call us: <a href={`tel:${phoneNumber}`} className="font-semibold">{phoneNumber}</a></span>
              <span>Email us: <a href={`mailto:${email}`}><span className="font-semibold">{email}</span></a></span>
              <p className="pt-4 text-xs">
                Jon Spencer Technical Drawing Limited, registered as a limited company in England and Wales under company number: 04901563.
                <span className="flex">Registered Company Address: 15 Cheltenham Close, Rushden, NN10 0YN.</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
query contactPage {
    contentfulContactContent {
      title
      bannerContent {
        bannerContent
      }
      bannerImage {
        file {
          url
        }
      }
      businessLocation {
        lon
        lat
      }
      phoneNumber
      email
      address {
        address
      }
    }
  }
`



