import React, { useState} from 'react'

export default function ContactForm() {

    const blankForm = {
            name: "",
            subject: "",
            email: "",
            message: ""
    }
    const [formVal, setFormVal] = useState(blankForm)



    const handleChange = e => setFormVal({...formVal, [e.target.name]: e.target.value });
    return (
        <form className="flex flex-col" name="contact" method="POST" data-netlify="true" netlify-honeypot="bot-field" action="/success">
            <input type="hidden" name="form-name" value="contact" />
            <div hidden aria-hidden="true">
                <label>
                    Don’t fill this out if you're human:
                     <input name="bot-field" />
                </label>
            </div>
            <label className="label">Subject:</label><input required value={formVal.subject || ""} type="text" name="subject" onChange={handleChange} />
            <label className="label">Your Name:</label><input required value={formVal.name || ""} type="text" name="name" onChange={handleChange}/>
            <label className="label">Your Email:</label><input required value={formVal.email || ""} type="email" name="email" onChange={handleChange}/>
            <label className="label">Message:</label><textarea required name="message" value={formVal.message || ""} onChange={handleChange}></textarea>
            <button className="btn btn-blue my-4" type="submit">Send</button>

        </form>

    )
}
